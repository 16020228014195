* {
  margin :0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.header {
  padding: 0px 0px;
  margin: 0px;
  width: 100%;
  background-color: #0f538b;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  font-size: .75em;
  color: white;

  & a{
    text-decoration: none;
    color: white;
    margin-left: 1em;
  }
}

.footer {
  padding: 0px;
  margin: 0px;
  margin-top: 5em;
  width: 100%;
  background-color: #0f538b;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  text-align: center;
  font-size: 0.75em;
  color: white;

  & p {
    margin: 0px;
  }
}

.App-link {
  color: #61dafb;
}

/*
 * Localised styling for the ContactCard component
 */

.contact-card {

  padding: 1rem;

  & .card {
    border-radius: 10px;
    background-color: #0f538b;
    color: #ffffff;

    & .card-content-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: last baseline;
    }

    & .header-left {
      padding: .5rem;
    }

    & .header-right {
      padding: .5rem;
      max-width: 75%;
    }

    & .detail-left {
      padding: .5rem;
    }

    & .detail-right {
      padding: .5rem;
      align-content: end;
    }

    & h1 {
      font-size: 1.2em;
      font-weight: bold;
    }

    & h2 {
      font-size: 1em;
      font-weight: normal;
    }

    & img { /* fit the image within the div */
      height: auto;
      width: 100%;
      max-width: 100px;
      border-radius: 10%;
      border: 4px solid #ffffff;
      overflow: hidden;
    }

    & p {
      font-size: 0.9em;
      margin:auto;
    }

    qr-code {
      margin: auto;
      min-width: 128px
    }
  }
}

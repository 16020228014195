.download-contact-card {
  display: flex;
  justify-content: center;
  
  & button {
    background-color: #4CAF50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}